const PlatformsEnum = Object.freeze({
    CAFE24: { value: 'CAFE24', displayName: 'CAFE24' },
    MUSINSA: { value: 'MUSINSA', displayName: '무신사' },
    W_CONCEPT: { value: 'W_CONCEPT', displayName: 'W콘셉트' },
    OCO: { value: 'OCO', displayName: 'OCO' },
    CM29: { value: 'CM29', displayName: '29cm' },
    EQL: { value: 'EQL', displayName: 'THE HANDSOME' },
    KREAM: { value: 'KREAM', displayName: 'KREAM' },
    CAFE24_PRT: { value: 'CAFE24_PRT', displayName: 'CAFE24(PRT)' },
    SSF: { value: 'SSF', displayName: 'SSF' }
});

export default PlatformsEnum;