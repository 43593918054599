import React, { useState, useEffect } from 'react';
import { Container, Table, Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { collectSalesFetch } from '../api/CollectSales';
import { collectStocksFetch } from '../api/CollectStocks';
import { retrieveAllSessions } from '../api/RetrieveSessions';
import { updateStocksFetch } from '../api/UpdateStocks';
import { retrieveCodeSetting } from '../api/RetrieveCodeSetting';
import { updateCodeSetting, convertToCSV } from '../api/UpdateCodeSetting';
import { downloadSalesData } from '../api/DownloadSalesData';
import PlatformsEnum from '../constants/PlatformsEnum';
import { redirectSessionOut } from '../api/Auth';
import { useNavigate } from 'react-router-dom';
import { downloadStocksData } from '../api/DownloadStocksData';
import { downloadExtension } from '../api/DownloadExtension';
import { retrieveExtensionVersion } from '../api/RetrieveExtensionVersion';
import './SettingsPage.css';

function SettingsPage() {
  const [productCodes, setProductCodes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editRecordIndex, setEditRecordIndex] = useState(null);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [salesUpdating, setSalesUpdating] = useState(false);
  const [stocksUpdating, setStocksUpdating] = useState(false);
  const [productCodeUpdateLoading, setProductCodeUpdateLoading] = useState(false);
  const [extensionVersion, setExtensionVersion] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // 코드 세팅 데이터를 가져옵니다.
    const retrieveCodeSettingData = async () => {
      try {
        const response = await retrieveCodeSetting();
        setProductCodes(response);
        setLoading(false);
      } catch (err) {
        if (err.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
        addLog(err.message);
      }
    };

    // 컴포넌트가 마운트될 때 코드 세팅 데이터를 가져옵니다.
    retrieveCodeSettingData();
  }, [navigate]); // 빈 배열은 컴포넌트가 처음 마운트될 때 한 번만 실행됨.

  useEffect(() => {
    // 크롬 확장프로그램 버전을 가져옵니다.
    const updateRetrieveExtensionVersion = async () => {
      try {
        const response = await retrieveExtensionVersion();
        setExtensionVersion(response.version);
      } catch (err) {
        if (err.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
        addLog(err.message);
      }
    };

    // 컴포넌트가 마운트될 때 확장프로그램 버전을 가져옵니다.
    updateRetrieveExtensionVersion();
  }, [navigate]); // 빈 배열은 컴포넌트가 처음 마운트될 때 한 번만 실행됨.

  useEffect(() => {
    // 크롬 확장프로그램 메시지 리스너 등록
    window.addEventListener("message", (event) => {
      if (event.data.type === "extension_response") {

        if (event.data.data.api === "collectStock") {
          if (event.data.data.status === "success") {
            if (event.data.data.results?.length > 0) {
              addLog("Start updating a spreadsheet with stock data");
              updateStocksFetch(event.data.data.results).then((_) => {
                setStocksUpdating(false);
                alert("업데이트가 완료되었습니다.");
              });
            } else {
              setStocksUpdating(false);
              alert("수집 된 데이터가 없습니다.");
            }
          } else {
            setStocksUpdating(false);
            alert("수집 중 오류가 발생했습니다.");
          }
        } else if (event.data.data.api === "collectSales") {
          if (event.data.data.status === "success") {
            setSalesUpdating(false);
            alert("수집이 완료되었습니다.");
          } else {
            setSalesUpdating(false);
            alert("수집 중 오류가 발생했습니다.");
          }
        }
      } else if (event.data.type === "extension_log") {
        addLog(event.data.data);
      }
    });
  }, []);

  // 로그 메시지를 추가합니다.
  const addLog = (message) => {
    if (message) {
      if (message instanceof Error) {
        setLogs(prevLogs => [...prevLogs, message.message]);
      } else if (typeof message === 'object') {
        setLogs(prevLogs => [...prevLogs, JSON.stringify(message)]);
      } else {
        setLogs(prevLogs => [...prevLogs, message]);
      }
    }
  };

  // 현재 테이블에서 데이터를 취득해서 성형한후 API로 전송
  const updateCodeSettingData = async () => {
    setProductCodeUpdateLoading(true);
    await updateCodeSetting(convertToCSV(productCodes));
    setProductCodeUpdateLoading(false);
    alert("업데이트가 완료되었습니다.");
  };

  // 구글 확장프로그램을 다운로드
  const downloadExtensionClick = async () => {
    try {
      downloadExtension();
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
    }
  };

  // 현재 선택중인 날짜를 기준으로 매출데이터를 다운로드
  const downloadSales = async () => {
    try {
      setSalesUpdating(true);
      const platforms = getSelectedSalesPlatforms();
      const platformValues = platforms.map(platform => platform.value);
      await downloadSalesData(startDate, endDate, platformValues);
      setSalesUpdating(false);
      alert("다운로드가 완료되었습니다.");
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
      setSalesUpdating(false);
    }
  };

  // 현재 선택중인 플랫폼의 재고데이터를 다운로드
  const downloadStocks = async () => {
    try {
      setStocksUpdating(true);
      const selectedStockPlatforms = getSelectedStockPlatforms();
      const sessions = await retrieveAllSessions();

      const selectedSessions = {};
      for (const selectedStockPlatform of selectedStockPlatforms) {
        const selectedStockPlatformName = selectedStockPlatform.value;
        // check include platform
        if (selectedStockPlatformName in sessions) {
          selectedSessions[selectedStockPlatformName] = sessions[selectedStockPlatformName];
        }
      }

      await downloadStocksData(selectedSessions);
      setStocksUpdating(false);
      alert("다운로드가 완료되었습니다.");
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
      setStocksUpdating(false);
    }
  };

  // 모든 체크박스를 해제합니다.
  const uncheckAll = (name, event) => {
    const checkboxes = document.querySelectorAll(`input[name="${name}"]`);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    event.target.checked = false;
  };

  // 테이블의 필드를 클릭했을 때 수정할 수 있도록 처리합니다.
  const handleFieldClick = (recordIndex, field) => {
    setEditRecordIndex(recordIndex);
    setEditField(field);
    setEditValue(productCodes[recordIndex][field]);
  };

  // 수정된 필드의 값을 저장합니다.
  const handleFieldChange = (event) => {
    setEditValue(event.target.value);
  };

  // 수정된 필드의 값을 저장하고 수정 상태를 해제합니다.
  const handleFieldBlur = () => {
    const updatedProductCodes = [...productCodes];
    updatedProductCodes[editRecordIndex][editField] = editValue;
    setProductCodes(updatedProductCodes);
    setEditRecordIndex(null);
    setEditField(null);
  };

  // 행을 추가합니다.
  const addRow = () => {
    const newRow = {
      no: productCodes.length + 1,
      code: '',
      name: `New Product ${productCodes.length + 1}`,
      localCost: '',
      currencyRate: '',
      MUSINSA: '',
      CAFE24: '',
      W_CONCEPT: '',
      OCO: '',
      CM29: '',
      EQL: '',
      KREAM: '',
      CAFE24_PRT: '',
      SSF: '',
    };
    setProductCodes([...productCodes, newRow]);
  };

  // 행을 삭제합니다.
  const deleteRow = (index) => {
    const updatedProductCodes = productCodes.filter((_, i) => i !== index);
    const renumberedProductCodes = updatedProductCodes.map((product, i) => ({
      ...product,
      no: i + 1,
    }));
    setProductCodes(renumberedProductCodes);
  };

  // 선택된 플랫폼을 가져옵니다.
  const getSelectedStockPlatforms = () => {
    const checkboxes = document.querySelectorAll('input[name="stock-platforms"]:checked');
    const selectedPlatforms = [];

    checkboxes.forEach(checkbox => {
      const label = checkbox.nextSibling.textContent;
      const matchingPlatform = Object.values(PlatformsEnum).find(platform => platform.displayName === label);

      if (matchingPlatform) {
        selectedPlatforms.push(matchingPlatform);
      }
    });

    return selectedPlatforms;
  };

  // 선택된 플랫폼을 가져옵니다.
  const getSelectedSalesPlatforms = () => {
    const checkboxes = document.querySelectorAll('input[name="sales-platforms"]:checked');
    const selectedPlatforms = [];

    checkboxes.forEach(checkbox => {
      const label = checkbox.nextSibling.textContent;
      const matchingPlatform = Object.values(PlatformsEnum).find(platform => platform.displayName === label);

      if (matchingPlatform) {
        selectedPlatforms.push(matchingPlatform);
      }
    });

    return selectedPlatforms;
  };

  // 매출 데이터를 업데이트합니다.
  const updateSales = async () => {
    try {
      setSalesUpdating(true);
      const sessions = await retrieveAllSessions();
      const selectedSalesPlatforms = getSelectedSalesPlatforms();
      const pemontSessionId = localStorage.getItem('session_id');
      if (startDate === "" || endDate === "") {
        setSalesUpdating(false);
        alert("날짜를 입력해주세요.");
        return;
      }
      await collectSalesFetch(sessions, selectedSalesPlatforms, startDate, endDate, pemontSessionId);
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      }
      addLog(err.message);
    }
  }

  // 재고 데이터를 업데이트합니다.
  const updateStock = async () => {
    try {
      setStocksUpdating(true);
      const sessions = await retrieveAllSessions();
      const selectedSalesPlatforms = getSelectedStockPlatforms();
      const pemontSessionId = localStorage.getItem('session_id');
      await collectStocksFetch(sessions, selectedSalesPlatforms, pemontSessionId);
    } catch (err) {
      if (err.message === 'Unauthorized') {
        redirectSessionOut(navigate);
        return;
      } else if (err.message === 'Invalid parameters') {
        alert("플랫폼 로그인 정보를 찾을 수 없습니다.");
        setStocksUpdating(false);
        return;
      }
      addLog(err.message);
    }
  }

  const fields = [
    'code', 'name', 'localCost', 'currencyRate',
    'MUSINSA', 'CAFE24', 'W_CONCEPT', 'OCO', 'CM29',
    'EQL', 'KREAM', 'CAFE24_PRT', 'SSF'
  ];

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <div className="settings-page">
      <Container>
        <h1 className="my-4">Settings</h1>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Chrome Extension</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                <Button variant="info" onClick={downloadExtensionClick}>Extension Download (v{extensionVersion})</Button>
              </Col>
            </Row>
            <Table striped bordered hover className="text-left">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Stocks</span>
                      <div>
                        {stocksUpdating && <Spinner animation="border" role="status" />}
                        <Button variant="success" className="ml-2 m-2" onClick={updateStock}>Update</Button>
                        <Button variant="info" className="ml-2" onClick={downloadStocks}>Excel Download</Button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group className="d-inline-block">
                      {Object.keys(PlatformsEnum).map((platform) => (
                        <Form.Check inline type="checkbox" name="stock-platforms" label={PlatformsEnum[platform].displayName} key={platform} defaultChecked />
                      ))}
                      <Form.Check inline type="checkbox" label="체크해제" onClick={(event) => uncheckAll('stock-platforms', event)} />
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover className="text-left">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Sales</span>
                      <div>
                        {salesUpdating && <Spinner animation="border" role="status" />}
                        <Button variant="success" className="ml-2 m-2" onClick={updateSales}>Update</Button>
                        <Button variant="info" className="ml-2" onClick={downloadSales}>Excel Download</Button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group className="d-inline-block">
                      {Object.keys(PlatformsEnum).map((platform) => (
                        <Form.Check inline type="checkbox" name="sales-platforms" label={PlatformsEnum[platform].displayName} key={platform} defaultChecked={PlatformsEnum[platform].value !== 'MUSINSA'} disabled={PlatformsEnum[platform].value === 'MUSINSA'} />
                      ))}
                      <Form.Check inline type="checkbox" label="체크해제" onClick={(event) => uncheckAll('sales-platforms', event)} />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group as={Row}>
                      <Col xs={6}>
                        <Form.Label>Start Date:</Form.Label>
                        <Form.Control
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label>End Date:</Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>무신사 매출은 CAFE24에서 연동됩니다.</p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>API Logs</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                <Button variant="danger" onClick={() => setLogs([])}>Clear</Button>
              </Col>
            </Row>
            <div className="form-control text-start" style={{ height: '250px', overflowY: 'auto', whiteSpace: 'pre-wrap' }}>
              {logs.join('\n')}
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px' }}>
            <Card.Title>Product Codes</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                {productCodeUpdateLoading && <Spinner animation="border" role="status" />}
                <Button variant="primary" className="m-2" onClick={addRow}>Add Row</Button>
                <Button variant="success" onClick={updateCodeSettingData}>Update</Button>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead className="text-center">
                <tr>
                  <th>No.</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Local Cost</th>
                  <th>Currency Rate</th>
                  <th>MUSINSA</th>
                  <th>CAFE24</th>
                  <th>W_CONCEPT</th>
                  <th>OCO</th>
                  <th>29CM</th>
                  <th>EQL</th>
                  <th>KREAM</th>
                  <th>CAFE24_PRT</th>
                  <th>SSF</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {productCodes.map((product, index) => (
                  <tr key={index}>
                    <td>{product.no}</td>
                    {fields.map((field) => (
                      <td key={field} onClick={() => handleFieldClick(index, field)}>
                        {editRecordIndex === index && editField === field ? (
                          <input
                            type="text"
                            value={editValue}
                            onChange={handleFieldChange}
                            onBlur={handleFieldBlur}
                            autoFocus
                            className="wild-input"
                          />
                        ) : (
                          product[field]
                        )}
                      </td>
                    ))}
                    <td>
                      <Button variant="danger" onClick={() => deleteRow(index)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Platform Links</Card.Title>
            <Card.Text>
              <div className="link-group">
                <a href="https://eclogin.cafe24.com/Shop/" target="_blank" rel="noreferrer">CAFE24</a> | 
                <a href="https://partner.musinsa.com/auth/login" target="_blank" rel="noreferrer">무신사</a> | 
                <a href="https://partner-auth.29cm.co.kr/login?redirect_uri=https%3A%2F%2Fpartner-connect.29cm.co.kr%2Fdashboard" target="_blank" rel="noreferrer">29CM</a> | 
                <a href="https://partner.kream.co.kr/sign-in" target="_blank" rel="noreferrer">KREAM</a> | 
                <a href="https://po.thehandsome.com/pologin" target="_blank" rel="noreferrer">THE HANDSOME</a> | 
                <a href="https://www.ocokorea.com:7980/admin/account/login_form.do" target="_blank" rel="noreferrer">OCO</a> | 
                <a href="https://newpin.wconcept.co.kr/Auth/Login" target="_blank" rel="noreferrer">W CONCEPT</a> | 
                <a href="https://eclogin.cafe24.com/Shop/?url=Init&login_mode=3" target="_blank" rel="noreferrer">CAFE24(PRT)</a> | 
                <a href="https://withus.ssfshop.com/pologin" target="_blank" rel="noreferrer">SSF SHOP</a>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
export default SettingsPage;